@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Pangea+Text+Trial:wght@400&display=swap');

@layer base {
  :root {
    /* --pinkki: 350 100% 88%; */
    --pinkki: 190 100% 50%;
  }

  /* FYI: this is not used here atm as it is only set using useEventPageUrlTheme hook  */
  [data-theme='videosync-event-custom-dark'] {
    --radius: 0.25rem;
    --black: 0 0% 8%; /* #131313 */
    --white: 0 0% 100%; /* #FFFFFF */
    --background: 0 0% 5%; /* #0D0D0D */
    --foreground: 0 0% 100%; /* #000000 */
    --card: 0 0% 9%; /* #181818 */
    --primary: 166 100% 38%; /* #00C295 */
    --primary-alt: 166 100% 20%; /* #00664E */
    --primary-foreground: 166 100% 90%; /* #CCFFF3 */
    --muted: 0 0% 48%; /* #7A7A7A */
    --muted-alt: 0 0% 85%; /* #D8D8D8 */
    --muted-foreground: 0 0% 10%; /* #1A1A1A */
    --accent: 240 5% 96%; /* #F4F4F5 */
    --accent-foreground: 240 6% 10%; /* #1818B */
    --destructive: 10 95% 58%; /* #FA4E2D */
    --border: 0 0% 13%; /* #202020 */
  }

  [data-theme='videosync-event-custom-light'] {
    --radius: 0.25rem;
    --black: 0 0% 8%; /* #131313 */
    --white: 0 0% 100%; /* #FFFFFF */
    --background: 0 0% 96%; /* #F4F4F4 */
    --foreground: 0 0% 7%; /* #131313 */
    --card: 0 0% 100%; /* #FFFFFF */
    --primary: 166 100% 38%; /* #00C295 */
    --primary-alt: 166 100% 20%; /* #00664E */
    --primary-foreground: 166 100% 90%; /* #CCFFF3 */
    --muted: 0 0% 44%; /* #717171 */
    --muted-alt: 0 0% 84%; /* #D6D6D6 */
    --muted-foreground: 0 0% 10%; /* #1A1A1A */
    --accent: 240 5% 96%; /* #F4F4F5 */
    --accent-foreground: 240 6% 10%; /* #1818B */
    --destructive: 10 95% 58%; /* #FA4E2D */
    --border: 0 0% 92%; /* #EAEAEA */
  }

  /* TODO: This is currently VN-specific theme */
  [data-theme='videosync-library-light'] {
    --radius: 0.3125rem;
    --black: 0 0% 0%; /* #000000 */
    --white: 0 0% 100%; /* #FFFFFF */
    --background: 0 0% 100%; /* #FFFFFF */
    --foreground: 0 0% 12%; /* #1E1E1E */
    --card: 225 57% 97%; /* #F4F6FC */
    --primary: 224 56% 48%; /* #365ABD */
    --primary-alt: 219 73% 24%; /* #102F68 */
    --primary-foreground: 0 0% 100%; /* #FFFFFF */
    --muted: 0 0% 40%; /* #666666 (used to be #9C9C9C, but adjusted for contrast purposes) */
    --muted-alt: 218 6% 74%; /* #B8BBC0 */
    --muted-foreground: 0 0% 25%; /* #3F3F3F */
    --accent: 222 48% 95%; /* #EBEFF8 */
    --accent-foreground: 219 73% 24%; /* TBD */
    --destructive: 346 96% 54%; /* #FA194F */
    --border: 0 0% 76%; /* #C2C2C2 */
  }

  /* TODO: we should make this :root */
  /* FYI: These can be used in Library app or somewhere else where the event theme is not OK */
  [data-theme='videosync-ui-light'] {
    --radius: 0.25rem;
    --black: 0 0% 7%;
    --white: 0 0% 100%;
    --background: 0 0% 96%;
    --foreground: 0 0% 7%;
    --card: 0 0% 100%;
    --primary: 166 100% 38%;
    --primary-alt: 166 100% 20%;
    --primary-foreground: 166 100% 90%;
    --muted: 0 0% 44%;
    --muted-alt: 0 0% 44%;
    --muted-foreground: 0 0% 44%;
    --accent: 240 5% 96%;
    --accent-foreground: 240 5% 10%;
    --destructive: 9 95% 58%;
    --border: 0 5% 90%;
  }
  /* FYI: These can be used in Library app or somewhere else where the event theme is not OK */
  [data-theme='videosync-ui-dark'] {
    --radius: 0.25rem;
    --black: 0 0% 7%;
    --white: 0 0% 100%;
    --background: 240 4% 5%;
    --foreground: 0 0% 100%;
    --card: 0 0% 10%;
    --primary: 166 100% 38%;
    --primary-alt: 166 100% 20%;
    --primary-foreground: 166 100% 90%;
    --muted: 0 1% 64%;
    --muted-alt: 0 1% 64%;
    --muted-foreground: 0 1% 64%;
    --accent: 240 5% 96%;
    --accent-foreground: 240 5% 10%;
    --destructive: 9 95% 58%;
    --border: 0 0% 20%;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
  }

  * ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  * ::-webkit-scrollbar-track {
    background: hsl(var(--border));
  }

  * ::-webkit-scrollbar-thumb {
    background-color: hsl(var(--primary));
    border: 1px solid hsl(var(--primary));
    border-radius: 1.25rem;
  }
}
